.event-applications-export-btn {
  margin-left: auto;
}

.event-applications {
  &-loading {
    position: absolute;
    width: 100%;

    padding-top: 50px;
    //left: calc(50% - 50px);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
