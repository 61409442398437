@import '../../common/variables.scss';
@import '../../common/helper.scss';

.dashboard-page {
  width: 100%;
  max-width: 100%;
  padding: 20px;
  background-color: $color-White;
  color: $color-text;
  display: flex;
  justify-content: center;

  .banner {
    position: absolute;
    top: 80px;
    right: 25px; 
    background-color: white;
    padding: 20px;
    min-width: 580px; 
    min-height: 150px;
    border: 1px solid #ddd;
    box-shadow: 0 0 0 1px #ddd;
    border-radius: 7px;
    z-index: 1000;

    .rate-training-banner-close-banner {
      position: absolute; 
      top: 15px;
      right: 10px;
      background: none;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
    
    h5 {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
    }
  } 

  .dashboard-page-container {
    width: 1200px;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;

    &-small {
      @extend .dashboard-page-container;
      flex-direction: column;
    }

    .dashboard-section-profile {
      @include dashboardSection;
      width: 280px;
      align-self: flex-start;

      &-small {
        @extend .dashboard-section-profile;
        width: 100%;
      }
    }
  
    .dashboard-sections-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .dashboard-section {
        @include dashboardSection;
        min-height: 260px;
      }
    }
  }
}
